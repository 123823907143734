.autocomplete-dropdown {
  .MuiAutocomplete-tag {
    height: 20px;

    .MuiChip-label {
      padding-left: 8px;
    }

    .MuiChip-deleteIcon {
      height: 18px;
      margin: 0 0 0 -6px;
    }
  }

  .MuiFormLabel-root {
    white-space: nowrap;

    &:not(.MuiInputLabel-shrink) {
      width: calc(100% - 65px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.incomplete-option {
  color: red;
  margin-left: 4px;
}

.attribute {
  position: relative;
  margin-bottom: 0;

  .no-translations {
    position: absolute;
    font-size: 12px;
    line-height: 12px;
    padding-left: 2px;
    color: red;
    top: 100%;
    left: 0;
    padding-top: 1px;
  }
}