.button {
  padding: 6px !important;
  position: relative;
}

.headerComponent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonCounter {
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  border: 1px solid #cac5c5;
  background-color: #e0e0e0;
  font-weight: 600;

}

.view, .left, .right {
  display: flex;
  align-items: center;
}

.add {
  margin-left: 4px !important;
}

.view {
  margin-left: 8px;
  margin-right: 8px;
}

.view, .categories, .activeFilters {
  border-radius: 4px;
  box-shadow: 0 0 0 1px #cac5c5;
}

.search {
  margin-left: 4px !important;
}

.header {
  font-weight: 600;
  margin-bottom: 8px;
  align-self: center;
}

.categoriesPopup {
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 8px;
  }
}

.activeFiltersPopup {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > span {
      margin-right: 4px;
    }
  }
}

.counter {
  font-weight: normal;
}