.variant-card {
  border: 1px solid #e0e0e0;

  .card-header {
    border-bottom: 1px solid #e0e0e0;

    .MuiTypography-h5 {
      font-size: 14px;
      font-weight: 600;
    }

    .MuiCardHeader-action {
      margin-top: 0;
      margin-right: 0;
    }

    padding: 0 16px;
    background-color: transparent;

    .expand {
      transform: rotate(180deg);
      margin-left: auto;
      transition: all .2s ease-in-out;
    }

    .expandOpen {
      transform: rotate(0deg);
      transition: all .2s ease-in-out;
    }

  }

  .card-content {

    &:last-child {
      padding-bottom: 16px;
    }

    padding: 16px;

    > * {
      width: 100%;
    }

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .card-description {
    position: relative;
    width: 100%;
    &:last-child {
      padding-bottom: 16px;
    }

    padding: 16px;

    > * {
      width: 100%;
    }
  }

  .container {
    position: relative;
    width: 100%;
    max-width: 400px;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: #f6f6f6;
  }

  .card-description:hover .overlay {
    opacity: 1;
  }

  .icon {
    color: black;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    .expand {
      transform: rotate(180deg);
      margin-left: auto;
      transition: all .2s ease-in-out;
    }

    .expandOpen {
      transform: rotate(0deg);
      transition: all .2s ease-in-out;
    }
  }

}

* div.card {
  margin-bottom: 16px
}

* div.card ~ div.card {
  margin-bottom: 0
}