.box {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;

  .box-header {
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    background-color: #dadada;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box-content {
    padding: 16px;
    background-color: white;

    > * {
      width: 100%;
    }

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

* div.box {
  margin-bottom: 16px
}
