.related-product {
  display: inline-flex;
  flex-direction: column;

  .box-header {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 8px;

    .MuiFab-root {
      width: 30px;
      min-height: 0;
      height: 30px;
    }
  }


  .box-content {
    display: flex;
    position: relative;

    .square {
      min-width: 150px;
      width: 150px;
      margin-bottom: 0;
    }

    .delete-button {
      position: absolute;
      top: 16px;
      right: 16px;
      width: auto;
    }

    .related-product-details {
      margin-left: 8px;
      display: flex;
      flex-direction: column;

      .detail {
        display: flex;
        align-items: center;
        line-height: 24px;

        .title {
          font-weight: 600;
          margin-right: 4px;
        }
      }
    }
  }
}