.guideline {
  position: absolute;

  &:after {
    content: "";
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  &:hover:after {
    background-color: rgba(193, 193, 193, 0.51)
  }

  &.vertical {
    height: 100%;
    width: 1px;

    &:after {
      content: "";
      height: 100%;
      width: 9px;
      left: -4px;
    }
  }

  &.horizontal {
    width: 100%;
    height: 1px;

    &:after {
      content: "";
      width: 100%;
      height: 9px;
      top: -4px;
    }
  }
}