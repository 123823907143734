.photo-crop {
  > .MuiDialog-container {
    > .MuiPaper-root {
      height: 100%;
      padding: 24px;

      .photos {
        flex-grow: 1;
        display: flex;
        max-height: calc(100% - 136px);

        .left {
          background-color: rgb(238, 238, 238);
          border: 1px solid rgb(238, 238, 238);
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;

          .ReactCrop__image {
            background-color: white;
          }
        }

        .right {
          background-color: rgb(238, 238, 238);
          border: 1px solid rgb(238, 238, 238);
          height: 100%;
          width: 100%;
          margin-left: 12px;
        }
      }

      .photos-controls {
        flex-shrink: 0;
        height: 136px;
        display: flex;

        > * {
          border: 1px solid rgb(238, 238, 238);
          border-top: none;
          height: 100%;
          width: 100%;

          .title {
            margin-top: 8px;
            margin-left: 8px;
          }

          .row {
            margin-top: 4px;
            margin-bottom: 4px;
          }
        }

        .left {
          margin-right: 12px;

          .row {
            margin-top: 4px;
            margin-bottom: 4px;
          }

          .dimensions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 24px;
          }

        }

        .right {
          margin-left: 12px;
          display: flex;
          align-items: flex-start;

          .colors {
            margin-right: 16px;

            .circle-picker {
              margin-top: 4px;
              margin-left: 8px;
            }
          }

          .orientation {
            margin-right: 16px;

            button {
              padding: 4px 0;
              min-width: 40px;
              margin-top: 4px;
              margin-left: 8px;
            }
          }

          .options {
            margin-left: 4px;

            .title {
              margin-bottom: 4px;
            }

            .option {
              margin-left: 8px;
              margin-bottom: 2px;
              display: flex;
              width: 230px;
              justify-content: space-between;
            }
          }

          .checkbox-button {
            margin-top: 12px;
            margin-left: auto;
            margin-right: 8px;
          }
        }
      }
    }

    .actions {
      border: 1px solid rgb(238, 238, 238);
      position: absolute;
      right: 24px;
      bottom: 24px;
    }
  }
}