$headerHeight: 64px;

.content {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: calc(#{$headerHeight} + 16px);
  width: 100%;
}

.controls {
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}