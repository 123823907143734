.box {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  > div:not(:first-child) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}