.slider {
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 8px;

  > .label {
    margin-right: 8px;
    min-width: 62px;
    text-align: right;
  }

  > .MuiTextField-root {
    width: 50px;
    margin-right: 20px;
    flex-shrink: 0;

    input {
      padding: 5px 6px;
      text-align: center;
    }
  }
}