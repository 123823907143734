body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *, ol, ul {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

#root {
  display: flex;
}


@font-face {
  font-family: 'Jenson';
  src: local('Jenson'), url(./Fonts/Jenson.otf)
}

$grid-columns: 12 !default;
$grid-gutter-width: 16px !default;
$grid-breakpoints: (
        xs: 0px,
        md: 721px,
        lg: 961px,
        xl: 1280px
) !default;

@mixin make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $breakpoints: $grid-breakpoints
) {
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: ($gutter / 2);
    padding-left: ($gutter / 2);
  }
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
      }
      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }
    }
  }
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) ==null, '', '-#{$name}');
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min !=0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num==0, 0, percentage($num));
}

@include make-grid-columns();

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
}

.MuiFormLabel-asterisk {
  color: red;
}

.MuiFormLabel-root {
  &:not(.MuiInputLabel-shrink) {
    font-size: 14px;
  }
}