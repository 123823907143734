.description-and-notes {
  .MuiInputBase-multiline {
    textarea:first-of-type {
      min-height: 150px;
      max-height: 150px;
      overflow-y: auto !important;
    }

    .MuiInputAdornment-positionStart {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .yandex-info {
    display: flex;
    align-items: center;
    color: initial;
    text-decoration: none;
    background-color: white;
    z-index: 1;
    padding-left: 4px;
    padding-right: 4px;

    svg {
      margin-left: 4px;
      height: 20px;
      width: auto;
    }

  }
}