.MuiButton-root.checkbox-button {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  position: relative;

  .checkbox-blank-square {
    background-color: white;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 25px;
    height: 25px;
    z-index: 0;
  }

  .check-outline {
    margin-right: 4px;

    path {
      transform: scale(calc(48 / 36));
      transform-origin: center;
    }

    height: 30px;
    width: 30px;
  }

  svg {
    z-index: 0;
    fill: #e0e0e0;
  }

  &.MuiButton-containedPrimary {
    svg {
      fill: #3f51b5;
    }
  }

  .check {
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    width: 30px;
  }
}