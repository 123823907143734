.textArea {
  textarea {
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto !important;
  }
}

.box {
  > div:first-child {
    position: relative;
  }
}

.copy {
  position: absolute !important;
  right: 16px;
}