.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.with-background {
    background-color: rgba(193, 193, 193, 0.51);
  }
}