.main-content {
  flex-grow: 1;
  min-height: 0;
}

.ReactCrop__crop-selection {
  box-shadow: 0 0 0 9999em rgba(193, 193, 193, 0.51);
}

.toolbar {
  display: flex;
  justify-content: space-between;

  .menu-button-title {
    display: flex;
    align-items: center;
  }
}