.guidelines {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > .photo-container {
    width: 100%;
    max-height: 100%;
    position: relative;
    background-color: white;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}