.related-products {

  .search-products {
    background-color: white;
    margin-bottom: 16px;
  }

  .MuiList-root {
    padding: 0;

    .MuiListItem-root {
      padding: 0;
    }
  }

  .related-product {
    width: 100%;
  }

  .related-product:not(:last-child) {
    margin-bottom: 16px;
  }
}

.related-products-popper {

  .MuiAutocomplete-listbox {
    max-height: 70vh !important;
    padding: 0 !important;

    .MuiAutocomplete-option {
      padding: 0;

      .related-product {
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
}

