.photo-gallery {
  > .MuiDialog-container {
    > .MuiPaper-root {
      height: 100%;
      padding: 24px;

      .all-photos {
        flex-grow: 1;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: -8px;
        margin-bottom: 0;
        justify-content: flex-start;
        overflow-y: auto;
        margin-right: -24px;
        padding-bottom: 56px;

        .photo-with-delete {
          margin: 8px;
          display: inline-flex;
          position: relative;

          .square {
            width: 175px;
          }

          .MuiButtonBase-root:not(.MuiCheckbox-root) {
            position: absolute;
            right: 0;
            bottom: 0;
            min-width: 0;
            padding: 4px 6px;
          }
          .MuiCheckbox-root{
            position: absolute;
            top: 0;
            right: 0;
          }
        }

      }
    }

    .actions {
      border: 1px solid rgb(238, 238, 238);
      position: absolute;
      right: 24px;
      bottom: 24px;
      background-color: white;
    }
  }
}