.popper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 3px;
  width: 248px;
  padding: 8px 16px;
  color: lightgrey;
  background-color: white;
  position: relative;
  z-index: -1;

  span {
    color: black;
    text-align: center;
  }

  .buttons {
    display: flex;
    flex-direction: row;

    button + button {
      margin-left: 10px
    }

    button, a {
      margin-top: 8px;
      display: flex;
      align-items: center;

      span {
        color: white;
      }
    }
  }
}