.photos {
  display: flex;
}

.shopPhotos, .productPhotos {
  min-height: 100%;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;

  > div:last-child {
    padding-bottom: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.shopPhotos {
  margin-right: 8px !important;
  flex-grow: 1;
}

.productPhotos {
  margin-left: 8px !important;
}

.photo {
  height: 190px;
  width: 190px;
  flex-shrink: 0;
}

.photoDraggableWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;

  > * {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.buttonWithIcon {
  min-width: 0 !important;

  svg {
    flex-shrink: 1;
  }
}

.activeCrop {
    background-color: #b2dfdb !important;
}

.list {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.controls {
  > *:not(:last-child) {
    margin-right: 8px;
  }
}
