@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

.notifications {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1201;
}

.notification {
  margin-left: 16px;
  margin-bottom: 16px;
  transform: translateX(-100%);
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.notification:not(:last-child) {
  margin-bottom: 8px;
}

