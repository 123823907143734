$headerHeight: 88px;

.cell {
  flex: 1;
  display: flex !important;
  padding: 8px !important;
  flex-direction: column;
  justify-content: center;
  font-size: 12px !important;
  align-items: center;
}

.even {
  background-color: #f5f5f5;
}

.buttons {
  display: flex !important;
  justify-content: flex-end;

  button:not(:last-of-type) {
    margin-right: 16px;
  }
}

.box {
  height: 100%;
  display: flex;
  flex-direction: column;

  > div:first-child {
    justify-content: flex-start;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  > div:not(:first-child) {
    padding: 0;
    height: 100%;
  }
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  background-color: white;
}

.clickable {
  cursor: pointer;
}

.filter {
  width: 100%;
}

.lastActive {
  background-color: rgb(213, 225, 253);
}

.column {
  border-right: 1px solid #dadada !important;
}