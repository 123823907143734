.absoluteSpinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(226, 226, 226, 0.94);
  z-index: 3;
}

.noBackground {
  background-color: transparent;
}