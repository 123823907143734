.body {
  padding: 8px;
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: column wrap;
}

.actions {
  border: 1px solid rgb(238, 238, 238);
  background-color: white;
  margin-left: auto;
  margin-bottom: 16px;
  margin-right: 24px;
}

.save {
  height: 36px !important;
  border-radius: 4px;
}

.button {
  margin-left: 8px !important;
}

.header {
  display: flex;
  align-items: center;
  align-self: center;
}

.removeAll {
  margin-bottom: 8px !important;
  margin-left: 4px !important;
}