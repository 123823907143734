.categories {
  .MuiAutocomplete-tag {
    height: 20px;

    .MuiChip-label {
      padding-left: 8px;
    }

    .MuiChip-deleteIcon {
      height: 18px;
      margin: 0 0 0 -6px;
    }
  }
}