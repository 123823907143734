$headerHeight: 88px;

.content {
  padding: 24px 24px;
}

.scrollable {
  overflow-x: visible;
  overflow-y: auto;
  max-height: calc(100vh - #{$headerHeight});
  padding: 24px 16px;
}

.header {
  > div:first-child {
    justify-content: flex-start;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  > div:not(:first-child) {
    padding: 0;
  }
}

.buttons {
  display: flex !important;
  justify-content: flex-end;

  button:not(:last-of-type) {
    margin-right: 16px;
  }
}

.evenRow, .oddRow {
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.evenRow {
  background-color: #f5f5f5;
}

.add {
  padding: 6px !important;
  margin-left: 4px !important;
}

.row {
  th, td {
    border-right: 1px solid #dadada !important;
  }
}