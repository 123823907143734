$headerHeight: 64px;
$tabsHeight: 48px;

.product-view {
  padding-top: $headerHeight;
  background-color: #FAFAFA;
  width: 100%;
  height: 100vh;
  position: relative;

  > .MuiCircularProgress-root {
  }

  .MuiTabs-root {
    height: $tabsHeight;
    position: absolute;
    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    width: 100%;
    z-index: 1;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    .MuiTab-root {
      min-width: auto;
      padding-left: 36px;
      padding-right: 36px;
      padding-bottom: 0;
    }

    .MuiTabs-indicator {
      background-color: #3f51b5;
      height: 3px;
    }
  }

  .scrollable {
    padding-left: 16px;
    padding-right: 16px;
    overflow-x: visible;
    overflow-y: auto;
    margin-top: $tabsHeight;
    max-height: calc(100vh - #{$headerHeight} - #{$tabsHeight});
    padding-bottom: 24px;
  }

  .product-content {
    overflow-x: visible;
    padding-top: 16px;
  }
}

.product-controls {
  height: 64px;
  align-items: center;
  display: flex;
  margin-left: -8px;
  margin-right: -32px;
  padding-left: 56px;
  padding-right: 24px;
  background: linear-gradient(90deg, rgba(62, 81, 181, 1) 0%, #002984 20%, #002984 100%);

  > .MuiButtonBase-root {
    margin-left: 4px;
    margin-right: 4px;
  }

  .MuiButtonBase-root:not(.checkbox-button) {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.MuiInputLabel-shrink {
  background-color: white;
  padding-right: 4px !important;
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-option {
    font-size: 14px;
  }
}

.discount-checkbox{
  width: max-content !important;
}