.files-uploaded {
  > .box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .files-control {
      margin-bottom: 8px;
    }

    .gallery {
      margin-left: 8px;
      margin-right: 8px;
    }
  }


  padding-top: 0;
}
