.filters {
  padding: 16px;

  > * {
    width: 100%;
  }
}

.filters-elements {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}
