.generate-field {
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;

    .MuiButton-root {
      box-shadow: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      text-transform: none;
    }
  }
}