.add, .edit {
    padding: 6px !important;
    margin-left: 4px !important;
}

.box {
    height: 100%;
    display: flex;
    flex-direction: column;

    > div:first-child {
        justify-content: flex-start;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.search {
    width: 100%;
}
