.add {
  padding: 6px !important;
  margin-left: 4px !important;
}

.photosIcons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;

  .icon {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.image {
  align-self: center;
  text-align: center !important;
}