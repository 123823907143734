
.dropzone-target {
  position: relative;

  .drag-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    border-style: dotted;
    border-color: #ccc;
    border-width: 2px;
    border-radius: 6px;
    padding: 25px;
    text-align: center;

    &.active {
      background-color: #b9bede;
    }
  }
}