.link {
  border-bottom: 1px solid #dadada !important;
}

.menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.avatar {
  transition: all 0.3s;
  min-width: initial !important;
  max-width: initial !important;
  width: 56px;
  overflow: hidden;

  > * {
    font-size: initial !important;
  }
}

.open {
  width: 0;
}