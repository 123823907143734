$headerHeight: 88px;

.content {
  padding: $headerHeight 24px 24px;
  background-color: #FAFAFA;
  width: 100%;
  height: 100vh;
  position: relative;
}

.incompleteOption {
  color: red;
  margin-left: 4px;
}