.controls {
  margin-left: -4px;
  margin-right: -4px;

  > .MuiButtonBase-root {
    margin-left: 4px;
    margin-right: 4px;
  }


  .delete-button, .clone-product {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}