.popper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 8px 16px;
  background-color: white;
  position: relative;
  z-index: -1;
  text-align: center;
  top: 4px;
  border: 1px solid #f50057;

  > span {
    margin-bottom: 8px;
  }

  &.primary {
    border-color: #3f51b5;
  }
}

.buttons {
  display: flex;

  > * {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}

.remove {
  width: 74px;
  border-radius: 4px;
  height: 36px !important;
}

.error {
  margin-bottom: 8px;
}

.loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}