.actions {
  border: 1px solid rgb(238, 238, 238);
  background-color: white;
  margin-left: auto;
  margin-bottom: 16px;
  margin-right: 24px;
}

.body {
  overflow: visible !important;
}

.add {
  width: 81px;
  height: 36px !important;
  border-radius: 4px;
}