.variants {
  flex-grow: 1;
}

.translation {
  padding-top: 6px;
  width: 100%;
}

.title {
  margin-bottom: 16px;
  font-weight: 600;
}

.button {
  width: 81px !important;
  height: 36px !important;
  border-radius: 4px;
}

.spacer {
  flex-grow: 1;
}

.row {
  display: flex;
  align-items: flex-start;
}

.removeButton {
  height: 36px;
  margin-top: 7px !important;
  margin-left: 8px !important;
}

.removeElementButton {
  height: 36px;
}