.userForm {
  display: flex;
  flex-direction: column;

  > div {
    width: 250px;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  > label {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}