.MuiFormControlLabel-root.checkbox {
  margin-left: 0;
  margin-right: 0;

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
  }
}