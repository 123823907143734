.square {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 16px;
  display: flex;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  color: #3f51b5;

  .square-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    flex-grow: 1;

    svg {
      font-size: 64px;
    }
  }
}