.variant {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
}


.elements {
  margin-top: 16px
}