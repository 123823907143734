.brand-photo-preview {
  background-color: white;
  margin-bottom: 16px;

  .buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      margin-top: 4px;
      margin-bottom: 4px;
      padding-left: 0;
      padding-right: 0;
    }

    .button-with-icon {
      svg {
        margin-left: 4px;
        flex-shrink: 1;
      }
    }
  }
}

.photo-preview:last-child {
  margin-bottom: 0;
}