.loginWrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.loginForm {
  width: 100%;
  max-width: 350px;
}

.inProgress {
  height: 30px !important;
  border-radius: 4px;
}