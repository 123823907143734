.box {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div:first-child {
    justify-content: flex-start;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  > div:not(:first-child) {
    padding: 0;
    height: 100%;
  }
}


.row {
  display: flex;
  background-color: white;
}

.cell {
  background-color: white;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 8px;
  border-right: 1px solid #dadada !important;
}

.cell:not(.header) {
  justify-content: center;
  cursor: pointer;
  word-break: break-all;
}

.header {
  flex-direction: column;
  justify-content: flex-end;
}

.editButton {
  align-self: flex-end;
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.odd {
  background-color: #f5f5f5;
}

.lastActive {
  background-color: rgb(213, 225, 253);
}

.notSaved {
  background-color: rgb(253, 244, 213);
}

.select {
  width: 100%;
}

.productImage {
  max-width: 100%;
}

.gridCell {
  background-color: white;
  padding: 16px 2px 2px 16px;
  position: relative;

  .gridCellContent {
    height: 100%;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .gridCellLabels {
    height: 55px;
    position: absolute;
    bottom: 8px;
    left: 16px;
    white-space: nowrap;
  }

  .gridCellName {
    font-family: Jenson;
    font-size: 24px;
  }

  .imageContainer {
    background-color: rgba(0, 0, 0, 0.05);
    left: 16px;
    top: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;
  }

  .gridCellImage {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.lastActiveGrid {
  > * {
    background-color: rgb(213, 225, 253);
  }
}

.notSavedGrid {
  > * {
    background-color: rgb(253, 244, 213);
  }
}