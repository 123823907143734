.add {
  padding: 6px !important;
  margin-left: 4px !important;
}

.buttons {
  align-self: flex-end;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}