.elements {
  display: flex;
  flex-direction: column;
}

.element {
  display: flex;
  border-bottom: 1px solid #dadada;
}

.name {
  border-right: 1px solid #dadada;
  align-items: flex-start;

  button {
    min-width: 0;
    margin-left: 8px;
  }
}

.name, .image {
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.image {
  align-items: center;

  .buttons {
    flex-grow: 1;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
    }

    .label {
      color: red;
    }
  }
}

.element:not(:first-child) {
  .image {
    justify-content: center;
  }
}

.element:last-child {
  border-bottom: none;
}

button.add {
  margin-top: 16px;
  align-self: flex-end;
}

.wrapper {
  border: 1px solid #dadada;
  border-radius: 4px;
}

.button {
  margin-right: 4px !important;
}

.nameWithTranslation {
  display: flex;
  flex-direction: column;

  span {
    margin-left: 0;
  }
}