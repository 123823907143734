.variant-elements {
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.name-fields {
  width: 100%;
  text-align: justify;
  display: flex;
  align-items: center;
}

.description {
  color: black;
  font-weight: 600;
  word-wrap: break-word;

  > span {
    color: #505050;
    font-weight: 500;
  }
}