.combination {
  display: flex;
  justify-content: space-between;
}

.combinations {
  display: flex;
  flex-direction: column;
  border: 1px solid #dadada;
  border-radius: 4px;
}

.combination {
  border-bottom: 1px solid #dadada;
}

.combination:not(:first-child) {
  .combinationElement:not(:last-child) {
    align-items: center;
    justify-content: center;
  }

  .combinationElement:last-child {
    align-items: flex-start;
  }
}

.combination:last-child {
  border-bottom: none;
}

.combinationElement {
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: 1px solid #dadada;;
  }
}