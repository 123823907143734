.form {
  display: flex;
  flex-direction: column;
}


.imageTitle {
  margin-top: 8px;
  margin-bottom: 4px;
}

.add {
  width: 81px;
  height: 36px !important;
  border-radius: 4px;
}

.otherProductElement {
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}

.or {
  margin-top: 16px;
  margin-bottom: 16px;
}

.hide {
  display: none;
}

.upload {
  width: 300px;
  margin-bottom: 16px;

  span {
    margin-left: 8px;
  }
}