.controls {
  display: flex;
  margin-bottom: 7px;
  border-bottom: 1px solid #dadada;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: flex-start;
}

.tabs {
  position: static !important;
  background-color: transparent !important;
  box-shadow: none !important;
  min-height: 0 !important;
  height: auto !important;

  > div > div > button {
    min-height: 0 !important;
    padding-bottom: 8px !important;
  }
}

.forSale {
  flex-shrink: 0;
  max-height: 30px;
}