.brochure-2D-3D {
  margin-bottom: 16px;
  margin-top: 8px;

  .uploaded-file {
    .square-content {
      width: 100%;

      .file-name {
        display: flex;
        width: 100%;
        justify-content: center;

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-right: -8px;
    justify-content: center;

    > * {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    .download-button {
      svg {
        margin-left: 4px;
        flex-shrink: 1;
      }
    }
  }
}

