.card {
  border: 1px solid #e0e0e0;

  .card-header {
    .MuiTypography-h5 {
      font-size: 14px;
      font-weight: 600;
    }

    .MuiCardHeader-action {
      margin-top: 0;
      margin-right: 0;
    }

    padding: 0 16px;
    background-color: #e0e0e0;

    .expand {
      transform: rotate(0deg);
      margin-left: auto;
      transition: all .2s ease-in-out;
    }

    .expandOpen {
      transform: rotate(180deg);
      transition: all .2s ease-in-out;
    }

    .add, .clone {
      svg {
        font-size: 25px;
      }
    }

    .add {
      svg {
        color: rgb(76, 175, 80);
      }
    }
  }

  .card-content {

    &:last-child {
      padding-bottom: 16px;
    }

    padding: 16px;

    > * {
      width: 100%;
    }

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

* div.card {
  margin-bottom: 16px
}

* div.card ~ div.card {
  margin-bottom: 0
}